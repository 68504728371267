/* Career Page Styles */
h2{
    text-align: center;
  }
   
h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .job-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .job-card {
    background-color: #787ce4;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 30vh;
  }

  .generalInfo-card {
    background-color: #44BBA4;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .job-card:hover {
    background-color: #0e83e3;
  }
  
  .job-card h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .job-card h3 {
    font-size: 16px;
    color: #ffffff;
  }
  
  /* Job Modal Styles */

  .job-modal {
    background-color: #659DBD;
    padding: 20px;
    border-radius: 5px;
    width: 80vw;
    
  }
  
  .job-modal h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .job-modal p {
    margin-bottom: 10px;
    text-align: start;
  }
  
  .job-modal ul {
    padding-left: 20px;
    margin-bottom: 10px;
    text-align: start;
  }
  
  .job-modal button {
    padding: 10px 20px;
    background-color: #fbf9f9;
    color: #121212;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .job-modal button:hover {
    background-color: #555;
  }
  
  .job-modal .modal-content {
    /* background-color: #0d4576; */
    background: linear-gradient(65deg, #5958a0 0%, #111b51 100%);
    color: white;
  }
  
  .job-modal .modal-header {
    /* background-color: #0d4576; */
    background: linear-gradient(65deg, #5958a0 0%, #111b51 100%);
    color: white;
    border-bottom: none;
  }
  
  .job-modal .modal-footer {
    background-color: #0d4576;
    background: linear-gradient(65deg, #5958a0 0%, #111b51 100%);
    color: white;
    border-top: none;
  }
  
  .modal-heading {
    font-weight: bold;
  }
  
  /* table */
  .job-table {
    margin: 0 auto;
  }
  .job-table tbody tr:nth-child(even) {
    background-color: #fbfb60 !important;
  }
  
  .job-table tbody tr:nth-child(odd) {
    background-color: #35f6cf !important;
  }
  
  .job-table tbody tr:hover {
    background-color: #fca7a7;
    transition: background-color 0.3s ease;
  }
  
  .new-banner {
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
    margin-right: 6px; /* To create a small space between the banner and the button */
  }