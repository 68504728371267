 h2{
  text-align: center;
}
h3{
  text-align: center;
}
 
  .WhoRWe{
    display: flex;
    padding: 40px;
  }
  @media (max-width: 991px) {
    .WhoRWe{
      display: flex;
      padding-right: 40px !important;
    }
  }
  .WhoRWe-left{
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
  }
  .WhoRWe-right{
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid #0e3a5a;
    text-align: justify;
    margin-left: 0;
  }
  .WhoRWe-text{
    padding-left: 3rem !important;
  }
  .Achievements-image{
    height: 8rem;
    width: 8rem;
    margin: 1rem;
  }
  .group-card-wrapper-AboutUs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .group-card-content-AboutUs {
    flex: 1;
    margin-left: 20px;
  }
  
  .group-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .group-card-image img {
    max-width: 50%;
    max-height: 50%;
    margin: 2rem
  }
  @media (max-width: 1441px) {
    .group-card-image img {
      max-width: 80%;
      max-height: 80%;
      margin: 2rem
    }
  }

  .vision-background{
    background-color: #1C448E;
    width: 70vw;
  }
  .mission-background{
    background-color: #1C448E;
    width: 70vw;
  }
  .founder-img{
    height: 18rem;
    width: 14rem;
  }
  .journey-card {
    margin: 5rem;
    margin-top: 1rem;
    border-radius: 10px;
    padding: 20px;
    transition: background-color 0.3s;
    text-align: justify;
    color: black;
    font-size: 17px;
  }
  
  /* .journey-card:hover {
    background-color: #659DBD;
    color: #fff;
    cursor: pointer;
  } */
  .founders-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  @media (min-width: 760px) {
    .WhoRWe-text {
      padding-right: 4rem
    }
  }
  