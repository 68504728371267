* {
  margin: 0%;
  padding: 0%;
}

.intro_wrapper {
  position: relative;
  padding: 200px 0 0;
}

.intro_wrapper:before {
  background: transparent;
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.intro_wrapper h1 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 900;
  color: #ffffff;

  margin: 0;
  letter-spacing: 2px;
}

.intro_wrapper p {
  font-size: 20px;
  color: #ffffff;
  line-height: 36px;
  padding: 30px 0 40px;
}

.intro_wrapper .intro_button a {
  background: #4285f4;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  color: #ffffff;
  padding: 0 45px;
  border-radius: 50px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.intro_wrapper .intro_button a:hover {
  background: #092ace;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.intro_text {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pages_links a {
  margin-right: 15px;
  color: #ffffff !important;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}
/* ---------------  single course on courses  page*/

.single-courses {
  background: #ffffff;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.single-courses:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.single-courses .courses_banner_wrapper {
  position: relative;
}

.single-courses .courses_banner img {
  width: 100%;
  height: auto;
  transform: scale(1.06) rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.single-courses:hover .courses_banner img {
  transform: scale(1.1) rotate(4deg);
}

.single-courses .purchase_price {
  position: absolute;
  text-align: right;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 100;
}

.single-courses .purchase_price a {
  background: #4285f4;
  font-weight: 600;
  border-radius: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 18px;
  padding: 5px 25px;
  text-decoration: none;
}

.single-courses .courses_info_wrapper {
  padding: 20px;
}

.single-courses .courses_title h3 a {
  color: #323232;
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  transition: color 0.3s ease-in-out;
}

.single-courses .courses_title h3 a:hover {
  color: #4285f4;
}

.single-courses .courses_info ul {
  margin-bottom: 0;
  list-style: none;
}

.single-courses .courses_info ul li {
  color: #6a7a83;
  font-size: 14px;
  margin-bottom: 6px;
}

.single-courses .courses_info ul li i {
  padding-right: 10px;
}

.single-courses .courses_info .cart_btn {
  background: #4285f4;
  font-weight: 500;
  border-radius: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  transition: background 0.3s ease-in-out;
}

.single-courses .courses_info .cart_btn:hover {
  background: #092ace;
}
/* ---------------------------- contact page css */

.contact_info_wrapper {
  padding: 120px 0;
  position: relative;
}
.contact_info_wrapper::before {
  content: "";
  background-image: url(/public/images/banner/map_shape.png);
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85%;
  z-index: -1;
}

.contact_info_wrapper .title {
  font-size: 40px;
  font-weight: 700;
  color: #333146;
  margin-bottom: 30px;
}

.contact_info_wrapper .contact_info p {
  padding-right: 50px;
  position: relative;
  z-index: 1;
}
.contact_info_wrapper .contact_info .details_title h3 {
  margin-bottom: 60px;
  font-size: 30px;
  font-weight: 700;
  color: #333146;
}

.event_location_info {
  padding: 30px 0;
}

.event_location_info ul {
  padding-left: 90px;
}

.event_location_info ul li {
  margin-bottom: 25px;
}

.event_location_info ul li:last-child {
  margin-bottom: 0;
}

.event_location_info ul li .info_title {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: #4285f4;
  margin-bottom: 5px;
  position: relative;
}

.event_location_info ul li .info_title:before {
  content: "";
  background: #4285f4;
  height: 3px;
  width: 50px;
  left: -90px;
  top: 12px;
  position: absolute;
}

.event_location_info ul li ul {
  padding-left: 0;
}

.event_location_info ul li ul li {
  margin-bottom: 0;
  line-height: 26px;
  color: #6a7a83;
  font-size: 14px;
}

.event_location_info img.contact__info_shpae {
  position: absolute;
  bottom: -65px;
  right: 0;
  z-index: -1;
  opacity: 0.3;
}

.contact_form_wrapper .leave_comment .form-group {
  margin-bottom: 20px;
  padding-left: 20px;
}

.contact_form_wrapper .leave_comment .form-group input,
.contact_form_wrapper .leave_comment .form-group textarea {
  background: transparent;
  border: 1px solid #d7d7d7;
  border-radius: 0;
  height: 46px;
  padding: 0 20px;
  transition: all 0.5s ease;
  width: 100%;
  color: #333146;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  box-shadow: none;
}

.contact_form_wrapper .leave_comment .form-group input:focus,
.contact_form_wrapper .leave_comment .form-group textarea:focus {
  border: 1px solid #333146;
  color: #333146;
}

.contact_form_wrapper .leave_comment .form-group input::placeholder,
.contact_form_wrapper .leave_comment .form-group textarea::placeholder {
  color: #6a7a83;
  font-size: 12px;
  font-weight: 400;
}

.contact_form_wrapper .leave_comment .form-group textarea {
  height: 170px;
  padding-top: 15px;
}

.contact_form_wrapper .leave_comment .submit-btn button {
  height: 60px;
  padding: 0 40px;
  font-size: 14px;
  font-weight: 500;
  background: #4285f4;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  outline: none;
  color: #ffffff;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
}

.contact_form_wrapper .leave_comment .submit-btn button:hover {
  background: #4285f4;
}

/* tabs in conurse detail  */

/* CourseDetails.css */

.courses-details-wrapper {
  padding: 20px;
}

.courses-details {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.courses-tab-wrapper {
  margin-top: 20px;
}

.tab-contents {
  font-size: 16px;
  line-height: 1.6;
}

.tab-pane h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tab-pane p {
  margin-bottom: 15px;
}

.step_point {
  list-style-type: none;
  padding-left: 30px;
}

.step_point li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
}

.step_point li:before {
  position: absolute;
  font-size: 14px;
  content: "\f103";
  left: 0;
  top: 0;
  color: #4285f4;
  font-weight: bold;
}

.social-wrapper {
  margin-top: 20px;
}

.social-items {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.social-items li {
  margin-right: 10px;
}

.social-items a {
  font-size: 20px;
  color: #4285f4;
}

.social-items a:hover {
  color: #4285f4;
}

/* ------------------- home slider */

.carousel-item-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 2;
}

.carousel-content {
  display: inline-block;
}

.carousel-caption-header {
  font-size: 32px;
  margin-bottom: 20px;
}

.carousel-buttons {
  margin-top: 20px;
}

.btn-primary {
  background-color: #4285f4;
  border: none;
}

.carousel-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.rev-slidebg {
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: inherit;
  z-index: 20;
}
.the-link:hover{
  color: blue !important ;
}
