@media (max-width: 768px) {
  .overviewDetail {
    width: 80vw !important;
  }
}

.this_back {
  background-color: #1c448e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-indicators [data-bs-target] {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50% !important;
}
.banner a:hover {
  text-decoration: underline;
}

/* Slider.css or inline style block */
.fade-carousel .carousel-item {
  opacity: 0;
}

.fade-carousel .carousel-item.active {
  opacity: 1;
}

/* Slider.css or inline style block */
.per-character-reveal {
  color: transparent; /* Start with transparent text color */
  animation: per-character-reveal-animation 1s linear forwards; /* Apply the animation */
}

@keyframes per-character-reveal-animation {
  0% {
    color: transparent;
    animation: per-character-reveal-animation 1s linear forwards;
  }
  100% {
    color: white;
    transform: translateX(0); /* Reveal text from left to right */
  }
}
.per-character-reveal-heading {
  opacity: 0;
  position: relative;
  top: 100%; /* Start position: below the container */
  animation: per-character-reveal-heading-animation 0.5s
    cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes per-character-reveal-heading-animation {
  0% {
    opacity: 0;
    top: 100%; /* Start below the container */
  }
  100% {
    opacity: 1;
    top: 0; /* Move to the original position */
  }
}

/* Slider.css or inline style block */
.button-group {
  opacity: 0;
  animation: button-fade-animation 1s linear forwards; /* Apply the animation */
}

@keyframes button-fade-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
